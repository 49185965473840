import { reactive } from 'vue'
import instance from './axiosUtils'
import axios from 'axios'

const protocol = reactive([])
const status = ref('ready')

function testcode() {
    // let url = 'https://grados-ai.s3.us-east-1.amazonaws.com/2024-11-17/a5443a3f-3ef8-4e3c-87e7-d250acf02938.json'
    let url = "https://grados-ai.s3.us-east-1.amazonaws.com/2024-11-20/fca8d43d-f255-47f6-bbf1-20da7d15d20f.json"
    axios.get(url).then((_protocol) => {
      protocol.splice(0, protocol.length, _protocol.data[0])
    })
  // protocol.splice(0, protocol.length, _protocol[0])
}
// testcode()
let paramsCache = {}
function imageSplit(params) {
  if (status.value === 'loading') {
    return
  }
  if (params) {
    paramsCache = params
  } else {
    params = paramsCache
  }
  status.value = 'loading'
instance.post('/image/split', {
  ...params
}).then((res) => {
    // protocol = res.data.imageProto
    if(res.data.imageProto) {
      axios.get(res.data.imageProto).then((_protocol) => {
        _protocol.data[0].child.forEach((element, i) => {
          element.visible = i === 0 ? true : false
        });
        protocol.splice(0, protocol.length, _protocol.data[0])
        status.value = 'ready'
    })
}
}).catch((err) => {
  status.value = 'error'
  console.log(err)
})
}
// imageSplit = testcode

function getProtocol(url) {
  return axios.get(url).then((_protocol) => {
    _protocol.data[0].child.forEach((element, i) => {
      element.visible = i === 0 ? true : false
    });
    protocol.splice(0, protocol.length, _protocol.data[0])
    status.value = 'ready'
})
}
export { protocol, imageSplit, status, getProtocol}
